<template>
  <div>
    <b-card title="Welcome to TaigaHost Email Manager - Admin tools 🚀">
      <b-card-text>Welcome to TaigaHost Email Manager - Admin tools</b-card-text>
    </b-card>

    <b-row>
      <b-col
        lg="12"
        cols="12"
      >
        <!--        <card-statistics-group />-->
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
// import CardStatisticsGroup from './CardStatisticsGroup.vue'

export default {
    components: {
        BCard,
        BCardText,
        BLink,
        // CardStatisticsGroup,
    },
}
</script>

<style>

</style>
